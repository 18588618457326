import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from 'gatsby'
import Layout from '../components/UI/layout/Layout'
import { GridContent } from '../components/UI/layout/grid-content'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import MaxiMenu from '../components/UI/navigation/maxi-menu'
import { Link } from 'gatsby'
import { AboveTheFold } from '../components/UI/layout/landing-page'

const Farm = ({ data }) => {
  const farm = data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  return (
    <Layout>
      <GatsbySeo title="Farm Page" />
      <AboveTheFold files={farm.introduction.videos} />
      <GridContent id="introduction" mode="white" layout="--2-column">
        <div className="text-content-margin">
          <h2>{farm.introduction.title}</h2>
          <h3>{farm.introduction.body}</h3>
        </div>

        <BgImage
          image={getImage(farm.introduction.image)}
          className="image-background float-right"
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'left center',
          }}
        />
      </GridContent>
      <GridContent
        id="farm-page-1"
        layout="--3-column content-margin-top content-margin-bottom"
        mode="sand-mode"
      >
        <GatsbyImage
          image={getImage(farm.page_1.image_left)}
          alt="background"
        />
        <div className="space-evenly">
          <h5 className="quote">{farm.page_1.centre.quote}</h5>
          <div
            className="markdown"
            dangerouslySetInnerHTML={{ __html: farm.page_1.centre.body }}
          />
        </div>
        <GatsbyImage
          image={getImage(farm.page_1.image_right)}
          alt="background"
        />
      </GridContent>
      <GatsbyImage
        id="full-bleed-image"
        image={getImage(farm.full_image)}
        alt="background"
        style={{ maxHeight: '60vh', width: '100%' }}
      />
      <GridContent id="farm-page-2" mode="white-mode" layout="--2-column">
        <BgImage
          className="image-background float-left"
          image={getImage(farm.page_2.image)}
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'right center',
          }}
        />
        <p className="text-content-margin">{farm.page_2.body}</p>
      </GridContent>
      <GridContent
        id="farm-page-3"
        mode="white-mode"
        layout="--2-column content-margin-top content-margin-bottom"
      >
        <div className="text-content-margin">
          <p className="text-content-margin">{farm.page_3.body}</p>
          <Link
            className="align-right mock-button"
            to={farm.page_3.button.link}
          >
            {farm.page_3.button.label}
          </Link>
        </div>
        <GatsbyImage
          image={getImage(farm.page_3.image_right)}
          alt="background"
        />
      </GridContent>
      <MaxiMenu />
    </Layout>
  )
}

export default Farm

export const query = graphql`
  query {
    allFile(filter: { name: { eq: "farm" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              introduction {
                body
                title
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                videos {
                  webm {
                    publicURL
                  }
                  mp {
                    publicURL
                  }
                }
              }

              full_image {
                childImageSharp {
                  gatsbyImageData(
                    width: 1200
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              page_1 {
                centre {
                  quote
                  body
                }

                image_left {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                image_right {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              page_2 {
                body
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              page_3 {
                body
                button {
                  label
                  link
                }
                image_right {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
